/**
 * Import functions from Redux library.
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Import functions from API.
 */
import classAPI from './classAPI';

/**
 * Thunk to make the API call asynchronously, then load all of the classes
 * retrieved into the class slice. Use the thunkAPI to reject if the API
 * call throws an error.
 */
export const fetchAllClasses = createAsyncThunk('classes/fetchAll',
  async({ token }, thunkAPI) => {
    try {
      const data = await classAPI.getAllClasses(token);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Another thunk for creating a new class.
 */
export const createClass = createAsyncThunk('class/create',
  async ({ classData, token }, thunkAPI) => {
    try {
      const data = await classAPI.postClass(classData, token);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Thunk for editing a class.
 */
export const editClass = createAsyncThunk('class/edit',
  async ({ classId, classData, token }, thunkAPI) => {
    try {
      let data = await classAPI.putClass(classId, classData, token);

      /**
       * Instead of deleting classes, their endDate is edited to be yesterday.
       * However, the status does not change until the backend does a reprovision.
       * Override the status to COMPLETED if the endDate is before today.
       */
      const today = new Date();
      const endDate = new Date(data.endDate);
      if (endDate < today) {
        data.status = 'COMPLETED';
      }

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Thunk for deleting a class.
 */
export const deleteClass = createAsyncThunk('class/delete',
  async ({ classId, token }, thunkAPI) => {
    try {
      const data = await classAPI.deleteClass(classId, token);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Create the store slice with the usual status and an empty list of courses as the
 * initial state.
 */
export const classSlice = createSlice({
  name: 'class',
  initialState: {
    status: 'idle',
    list: [],
    current: {},
    error: null
  },
  reducers: {
    /**
     * Completely empty the course list.
     */
    clearClassList: (state) => {
      return {
        ...state,
        list: []
      };
    },
    loadCurrentClass: (state, action) => {
      return {
        ...state,
        current: action.payload.class
      };
    },
    clearCurrentClass: (state) => {
      return {
        ...state,
        current: {}
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllClasses.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchAllClasses.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(fetchAllClasses.rejected, (state, action) => {
      state.list = [];
      state.status = 'failed';
      state.error = action.payload.message;
    });
    builder.addCase(createClass.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(createClass.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(createClass.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload.message;
    });
    builder.addCase(editClass.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(editClass.fulfilled, (state, action) => {
      state.list = state.list.map(c => {
        if (c.id === action.payload.id) {
          return action.payload;
        } else {
          return c;
        }
      });
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(editClass.rejected, (state, action) => {
      state.status = 'failed';
      state.status = action.payload.message;
    });
    builder.addCase(deleteClass.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(deleteClass.fulfilled, (state, action) => {
      state.list = state.list.filter(c => c.id !== action.payload.classId);
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(deleteClass.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload.message;
    });
  }
});

/**
 * Export selectors for easily accessing certain pieces of this slice.
 */
export const selectClassError = state => state.class.error;
export const selectClassList = state => state.class.list;
export const selectCurrentClass = state => state.class.current;
export const selectName = state => state.class.current.name;

/**
 * Export available actions to by used by components.
 * Note that the thunks are exported at the definition.
 */
export const { clearClassList, loadCurrentClass, clearCurrentClass } = classSlice.actions;

/**
 * Export the entire reducer itself. Use in the main store.js file.
 */
export default classSlice.reducer;
