import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom'
import {
  Flex,
  Icon
} from '@chakra-ui/react'
import { useOktaAuth } from '@okta/okta-react/';
import {
  FaChalkboardTeacher,
  FaCubes,
  FaDesktop
} from 'react-icons/fa'
import AuthLink from '../auth/AuthLink'
import Logo from './Logo'
import NavItem from './NavItem'

function Nav() {
  const auth = useSelector(state => state.auth);
  const { authState } = useOktaAuth();


  const isLoggedInAsStudent = () => {
    return (
      auth.email !== '' &&
      auth.passphrase !== '' &&
      auth.basic !== ''
    );
  }

  const isLoggedInAsAdmin = () => {
    return authState?.isAuthenticated;
  }

  return (
    <Flex
      alignItems="center"
      backgroundColor="brand.900"
      backgroundImage="/glyph.svg"
      color="white"
      gap={8}
      justifyContent="flex-end"
      padding={8}
      height={100}
      width="100%"
    >
      <Logo />
      {isLoggedInAsStudent() &&
        <NavItem href="/machines" text="Machines" icon={<Icon as={FaDesktop} />} />
      }
      {isLoggedInAsAdmin() &&
        <NavItem href="/courses" text="Courses" icon={<Icon as={FaCubes} />} />
      }
      {isLoggedInAsAdmin() &&
        <NavItem href="/classes" text="Classes" icon={<Icon as={FaChalkboardTeacher} />} />
      }
      <AuthLink />
      <Outlet />
    </Flex>
  )
}

export default Nav
