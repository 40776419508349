import {
  Alert,
  AlertIcon
} from '@chakra-ui/react';

const Error = ({ message }) => {
  return (
    <Alert
      backgroundColor="orange.500"
      borderRadius="lg"
      color="white"
      status="error"
    >
      <AlertIcon color="white" />
      {message}
    </Alert>
  );
}

export default Error;