/**
 * Import hooks from React, Redux, Okta libraries.
 */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

// Import UI components from Chakra, React, and local.
import {
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  List,
  ListItem,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import {
  FaCopy,
  FaEdit,
  FaPlus,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTrash
} from 'react-icons/fa';
import ConfirmationModal from '../common/ConfirmationModal';
import Error from '../common/Error';
import Loading from '../common/Loading';
import MainLayout from '../common/MainLayout';
import StatusIndicator from '../common/StatusIndicator';
import TableLayout from '../common/TableLayout';

// Import thunks and selectors from state slices.
import {
  clearCurrentClass,
  editClass,
  fetchAllClasses,
  loadCurrentClass,
  selectClassError,
  selectClassList
} from './classSlice';

const TABLE_COLUMNS = [
  {
    name: 'Status',
    key: 'status'
  },
  {
    name: 'Name',
    key: 'name'
  },
  {
    name: 'Instructor',
    key: 'instructor'
  },
  {
    name: 'Usernames',
    key: null
  },
  {
    name: 'Passphrase',
    key: null
  },
  {
    name: 'Schedule',
    key: 'startDate'
  },
  {
    name: 'Actions',
    key: null
  }
];

/**
 * ClassTable displays a heading and all classes with their
 * respective information like description and status.
 */
const ClassTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const token = authState?.accessToken.accessToken;
  const error = useSelector(selectClassError);
  const classList = useSelector(selectClassList);
  const [classToDelete, setClassToDelete] = useState({});

  // Hook for delete confirmation modal.
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose
  } = useDisclosure();

  // State for table data column sorting.
  const [improvedClassList, setImprovedClassList] = useState([]);
  const [sortColumn, setSortColumn] = useState('');
  const [isAscending, setIsAscending] = useState(true);

  // Fetch all of the class info with the thunk from classSlice.
  useEffect(() => {
    dispatch(fetchAllClasses({ token }));
  }, []);

  /**
   * Break out the datetimes into separate times and dates to display.
   * Only trigger this change when the original classList changes.
   */
  useEffect(() => {
    // Need a deep copy of the array.
    let newList = [...classList].map(c => {
      let newStartDate = new Date(c.startDate).toDateString();
      let newEndDate = new Date(c.endDate).toDateString()

      newStartDate = newStartDate.slice(0, -5) + ',' + newStartDate.slice(-5);
      newEndDate = newEndDate.slice(0, -5) + ',' + newEndDate.slice(-5);

      return {
        ...c,
        startDate: newStartDate,
        endDate: newEndDate
      }
    });

    newList = newList.filter(c => c.status !== 'COMPLETED');
    setImprovedClassList(newList);
  }, [classList]);

  // Sort the table whenever the sortColumn or isAscending state changes.
  useEffect(() => {
    // Need a deep copy of the array.
    const newList = [...improvedClassList].sort((a, b) => {
      let compareA, compareB;

      if (sortColumn === 'startDate') {
        compareA = new Date(a[sortColumn]);
        compareB = new Date(b[sortColumn]);
        compareA = compareA.getTime();
        compareB = compareB.getTime();
      } else {
        compareA = a[sortColumn];
        compareB = b[sortColumn];
      }

      if (isAscending) {
        return compareA > compareB ? 1 : -1;
      } else {
        return compareA < compareB ? 1 : -1;
      }
    });

    // Now that it's sorted, update the improved list.
    setImprovedClassList(newList);
  }, [sortColumn, isAscending]);

  const handleNew = () => {
    dispatch(clearCurrentClass());
    navigate('/classes/new');
  }

  const handleEdit = (classData) => {
    dispatch(loadCurrentClass({ class: classData }));
    navigate('/classes/edit');
  }

  const handleDelete = () => {
    // Create yesterday's date to send along to the backend.
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const classId = classToDelete.id;
    const classData = {
      attendees: classToDelete.attendees,
      courseId: classToDelete.courseId,
      instructor: classToDelete.instructor,
      name: classToDelete.name,
      startDate: yesterday.toISOString(),
      endDate: yesterday.toISOString(),
    };


    onConfirmationClose();
    dispatch(editClass({ classId, classData, token }));
  }

  const handleSortChange = (key) => {
    const newAscending = key === sortColumn ? !isAscending : true;
    setSortColumn(key);
    setIsAscending(newAscending);
  }

  // Reusable table row for the columns headings.
  const tableLabelRow = 
    <Tr>
      {TABLE_COLUMNS.map(column => {
        let sortIcon = FaSort;

        if (sortColumn === column.key) {
          sortIcon = isAscending ? FaSortDown : FaSortUp;
        }

        return (
          <Th key={column.name}>
            <Flex gap={2} alignItems="center">
              <Text>{column.name}</Text>
              {column.key && <Icon as={sortIcon} onClick={() => handleSortChange(column.key)} _hover={{ cursor: 'pointer' }} />}
            </Flex>
          </Th>
        );
      })}
    </Tr>;

  return (
    <MainLayout>
      {(classList.length === 0) && (error === null) && <Loading message="Loading classes" />}
      {(classList.length === 0) && error && <Error message={error} />}

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        handleAction={handleDelete}
        header="Delete Class"
        message="Are you sure? This will permanently erase this class."
        actionText="Delete"
      />

      <Flex alignItems="flex-end" justifyContent="space-between">
        <Heading as="h2" size="lg">Classes</Heading>
        <Button
          colorScheme="brand"
          leftIcon={<FaPlus />}
          onClick={handleNew}
          variant="solid"
        >
          Add Class
        </Button>
      </Flex>

      <TableLayout>
        <Thead>{tableLabelRow}</Thead>
        <Tbody>
          {improvedClassList.map(c => (
            <Tr key={c.id}>
              <Td><StatusIndicator status={c.status} /></Td>
              <Td>{c.name}</Td>
              <Td>{c.instructor}</Td>
              <Td>
                <List spacing={2}>
                  {c.emails.map(e => <ListItem key={e}>{e.replace('@forescout.academy', '')}</ListItem>)}
                </List>
              </Td>
              <Td>
                <Flex alignItems="center" gap={1}>
                  <Text>{c.passphrase}</Text>
                  <IconButton
                    aria-label="Copy passphrase"
                    colorScheme="gray"
                    icon={<FaCopy />}
                    onClick={() => navigator.clipboard.writeText(c.passphrase)}
                    size="sm"
                    variant="ghost"
                  />
                </Flex>
              </Td>
              <Td>
                <Flex flexDirection="column" gap={1}>
                  <Text>{c.startDate}</Text>
                  <Text>to</Text>
                  <Text>{c.endDate}</Text>
                </Flex>
              </Td>
              <Td>
                <Flex gap={2}>
                  <Button
                    colorScheme="darkBlue"
                    leftIcon={<FaEdit />}
                    onClick={() => handleEdit(c)}
                    size="xs"
                    variant="solid"
                  >
                    Edit
                  </Button>
                  <Button
                    colorScheme="orange"
                    leftIcon={<FaTrash />}
                    onClick={() => {
                      setClassToDelete(c);
                      onConfirmationOpen();
                    }}
                    size="xs"
                    variant="solid"
                  >
                    Delete
                  </Button>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>{tableLabelRow}</Tfoot>
      </TableLayout>
    </MainLayout>
  )
}

export default ClassTable;
