import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    black: {
      /*
      50: '#f2f2f2',
      100: '#f2f2f2',
      200: '#cccccc',
      300: '#cccccc',
      400: '#999999',
      500: '#999999',
      600: '#666666',
      700: '#333333',
      800: '#1a1a1a',
      900: '#000000'
      */
      50: '#333333',
      100: '#f2f2f2',
      200: '#333333',
      300: '#333333',
      400: '#333333',
      500: '#333333',
      600: '#333333',
      700: '#000000',
      800: '#000000',
      900: '#000000'
    },
    gray: {
      /*
      50: '#d9dde0',
      100: '#d9dde0',
      200: '#b3bbc1',
      300: '#b3bbc1',
      400: '#8d98a2',
      500: '#8d98a2',
      600: '#677683',
      700: '#546574',
      800: '#415464',
      900: '#415464'
      */
      50: '#546574',
      100: '#d9dde0',
      200: '#b3bbc1',
      300: '#b3bbc1',
      400: '#546574',
      500: '#546574',
      600: '#546574',
      700: '#415464',
      800: '#415464',
      900: '#415464'
    },
    orange: {
      /*
      50: '#fbeee6',
      100: '#fbeee6',
      200: '#f7dccd',
      300: '#f7dccd',
      400: '#efba9b',
      500: '#efba9b',
      600: '#e89768',
      700: '#e07536',
      800: '#d85204',
      900: '#d85204'
      */
      50: '#e07536',
      100: '#e07536',
      200: '#e07536',
      300: '#e07536',
      400: '#e07536',
      500: '#e07536',
      600: '#d85204',
      700: '#d85204',
      800: '#d85204',
      900: '#d85204'
    },
    blue: {
      /*
      50: '#d4dded',
      100: '#d4dded',
      200: '#aabbdb',
      300: '#aabbdb',
      400: '#7f99ca',
      500: '#7f99ca',
      600: '#5577b8',
      700: '#3f66af',
      800: '#2a55a6',
      900: '#2a55a6'
      */
      50: '#3f66af',
      100: '#3f66af',
      200: '#3f66af',
      300: '#3f66af',
      400: '#3f66af',
      500: '#3f66af',
      600: '#3f66af',
      700: '#2a55a6',
      800: '#2a55a6',
      900: '#2a55a6'
    },
    darkBlue: {
      /*
      50: '#ccd5e2',
      100: '#ccd5e2',
      200: '#99acc6',
      300: '#99acc6',
      400: '#6682a9',
      500: '#6682a9',
      600: '#33598d',
      700: '#1a447e',
      800: '#002f70',
      900: '#002f70'
      */
      50: '#1a447e',
      100: '#1a447e',
      200: '#1a447e',
      300: '#1a447e',
      400: '#1a447e',
      500: '#1a447e',
      600: '#002f70',
      700: '#002f70',
      800: '#002f70',
      900: '#002f70'
    },
    brand: {
      /*
      50: '#f2fafe',
      100: '#f2fafe',
      200: '#ccebfb',
      300: '#ccebfb',
      400: '#99d8f7',
      500: '#99d8f7',
      600: '#66c4f2',
      700: '#33b1ee',
      800: '#1aa7ec',
      900: '#009dea'
      */
      50: '#f2fafe',
      100: '#f2fafe',
      200: '#ccebfb',
      300: '#33b1ee',
      400: '#99d8f7',
      500: '#33b1ee',
      600: '#009dea',
      700: '#009dea',
      800: '#009dea',
      900: '#009dea'
    }
  },
  fonts: {
    body: 'Open Sans, sans-serif',
    heading: 'Montserrat, Times New Roman, sans-serif'
  },
  shadows: {
    outline: '0 0 0 2px #33b1ee'
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: 'brand.700'
      }
    },
    Link: {
      variants: {
        'button': {
          background: 'white',
          borderRadius: 'md',
          color: 'brand.500',
          paddingBottom: 2,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 2,
          _hover: {
            backgroundColor: 'brand.100',
            color: 'brand.900',
            textDecoration: 'none'
          }
        }
      }
    },
    NumberInput: {
      defaultProps: {
        focusBorderColor: 'brand.700'
      }
    },
    Select: {
      defaultProps: {
        focusBorderColor: 'brand.700'
      }
    },
    Table: {
      variants: {
        'striped': {
          th: {
            backgroundColor: 'brand.200',
            color: 'brand.900',
            fontWeight: 'bold',
            '&:first-of-type': {
              textAlign: 'center',
              width: '1rem'
            }
          },
        }
      }
    }
  }
});

export default theme;
