import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import machineReducer from './machine/machineSlice';
import classReducer from './class/classSlice';
import courseReducer from './course/courseSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    machine: machineReducer,
    class: classReducer,
    course: courseReducer
  }
});
