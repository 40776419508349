import {
  Box,
  Td,
  Text,
  Tr
} from '@chakra-ui/react'
import MachineActionList from './MachineActionList'
import StatusIndicator from '../common/StatusIndicator';

function MachineRow({ id, name, powerState, hasVmWare, isLoading, message }) {
  return (
    <Tr>
      <Td>
        <StatusIndicator status={powerState} />
      </Td>
      <Td>
        {name}
      </Td>
      <Td>
        {isLoading
          ? <Box height={8}>
              <Text fontWeight="bold" lineHeight={8}>Loading...</Text>
            </Box>
          : <MachineActionList
              machineId={id}
              machineState={powerState}
              hasVmWare={hasVmWare}
              message={message}
            />
        }
      </Td>
    </Tr>
  )
}

export default MachineRow;
