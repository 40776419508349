/**
 * Import UI components from Chakra, React, and local.
 */
import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';
import {
  FaBolt,
  FaEye,
  FaHandPaper,
  FaPowerOff,
  FaRedoAlt,
  FaSyncAlt,
} from 'react-icons/fa';
import MachineAction from './MachineAction';

function MachineActionList({ machineId, machineState, hasVmWare, message }) {
  let machineActions = [];

  switch(machineState) {
    /**
     * Machine is ready and taking up memory, but not started.
     * Reboot or start actions are available here.
     */
    case 'POWERED_OFF':
      if (hasVmWare) {
        machineActions.push(
          <MachineAction
            color="brand"
            command="reboot"
            icon={<FaRedoAlt />}
            key="Reboot"
            machineId={machineId}
            text="Reboot"
          />
        );
      }
      machineActions.push(
        <MachineAction
          color="darkBlue"
          command="start"
          icon={<FaBolt />}
          key="Start"
          machineId={machineId}
          text="Start"
        />
      );
      break;

    /**
     * Biggest selection of actions here for a machine that's
     * powered on. Reboot, reset, stop, shutdown, and view.
     */
    case 'POWERED_ON':
      if (hasVmWare) {
        machineActions.push(
          <MachineAction
            color="brand"
            command="reboot"
            icon={<FaRedoAlt />}
            key="Reboot"
            machineId={machineId}
            text="Reboot"
          />
        )
      }
      machineActions.push(
        <MachineAction
          color="brand"
          command="reset"
          icon={<FaSyncAlt />}
          key="Reset"
          machineId={machineId}
          text="Reset"
        />
      );
      machineActions.push(
        <MachineAction
          color="orange"
          command="stop"
          icon={<FaHandPaper />}
          key="Stop"
          machineId={machineId}
          text="Stop"
        />
      );
      if (hasVmWare) {
        machineActions.push(
          <MachineAction
            color="orange"
            command="shutdown"
            icon={<FaPowerOff />}
            key="Shut down"
            machineId={machineId}
            text="Shut down"
          />
        );
      }
      machineActions.push(
        <MachineAction
          color="darkBlue"
          command="view"
          icon={<FaEye />}
          key="View"
          machineId={machineId}
          text="View"
        />
      );
      break;

    /**
     * Suspension action is complete. Machine memory is now unused,
     * and waiting to be started up. Refresh is an action that's nice
     * to have here too.
     */
    case 'SUSPENDED':
      machineActions.push(
        <MachineAction
          color="brand"
          command="refresh"
          icon={<FaRedoAlt />}
          key="Refresh"
          machineId={machineId}
          text="Refresh"
        />
      );
      machineActions.push(
        <MachineAction
          color="darkBlue"
          command="start"
          icon={<FaBolt />}
          key="Start"
          machineId={machineId}
          text="Start"
        />
      );
      break;

    /*
     * Machine is in the middle of a suspension and no actions should
     * take place, besides refreshing to see if the suspension is complete.
     */
    case 'SUSPEND':
      machineActions.push(
        <MachineAction
          color="brand"
          command="refresh"
          icon={<FaRedoAlt />}
          key="Refresh"
          machineId={machineId}
          text="Refresh"
        />
      );
      break;

    default:
      // Add nothing.
  }

  return (
    <Flex gap={2}>
      {machineActions}
      {(message !== null) &&
        <Box height={8}>
          <Text fontWeight="bold" lineHeight={8}>{message}</Text>
        </Box>
      }
    </Flex>
  )

}

export default MachineActionList;
