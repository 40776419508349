import {
  Box,
  Button,
  Flex
} from '@chakra-ui/react';
import {
  FaCopy,
  FaPaste,
  FaStopCircle,
  FaExpandArrowsAlt
} from 'react-icons/fa';
import { useCallback, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { selectCurrentMachine } from './machineSlice';

function MachineView() {
  const wmksContainer = useRef();
  const [wmks, setWmks] = useState(null);
  const currentMachine = useSelector(selectCurrentMachine);

  useEffect(() => {
    if (typeof currentMachine.wmksUrl === 'undefined') {
      return;
    }

    const containerId = wmksContainer.current.id;

    const newWmks = window.WMKS.createWMKS(
      containerId, {
        rescale: true,
        changeResolution: true,
        retryConnectionInterval: 4000,
        position: window.WMKS.CONST.Position.CENTER
      }
    )
    
    newWmks.register(
      window.WMKS.CONST.Events.CONNECTION_STATE_CHANGE, (event, data) => {
        if (data.state === window.WMKS.CONST.ConnectionState.CONNECTED) {
          console.log('Connection state change: Connected');
        }
      }
    )
    
    newWmks.register(
      window.WMKS.CONST.Events.COPY, (event, data) => {
        navigator.clipboard.writeText(data)
      }
    )

    newWmks.register(
      window.WMKS.CONST.Events.ERROR, (event, data) => {
        console.error('Error: ', data);
      }
    )

    newWmks.connect(currentMachine.wmksUrl);
    setWmks(newWmks);
  }, [currentMachine]);

  const handleResize = () => {
    if (!wmks) return;
    wmks.updateScreen();
  }

  const debouncedHandleResize = useCallback(debounce(handleResize, 300), [wmks]);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
  });

  const handleCopy = async () => {
    if (!wmks) return;
    wmks.grab();
    await new Promise((resolve) => setTimeout(resolve, 500));
    wmks.ungrab()
  }

  const handlePaste = async () => {
    if (!wmks) return;
    const text = await navigator.clipboard.readText();
    wmks.sendInputString(text);
  }

  const handleCtrlAltDel = () => {
    if (!wmks) return;
    wmks.sendCAD();
  }

  const handleFullScreen = () => {
    if (!wmks) return;
    wmks.enterFullScreen();
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        backgroundColor="white"
        justifyContent="space-evenly"
        padding={2}
        textAlign="center"
      >
        <Button
          colorScheme="gray"
          leftIcon={<FaCopy />}
          onClick={handleCopy}
          variant="ghost"
        >
          Copy
        </Button>
        <Button
          leftIcon={<FaPaste />}
          onClick={handlePaste}
          variant="ghost"
        >
          Paste
        </Button>
        <Button
          leftIcon={<FaStopCircle />}
          onClick={handleCtrlAltDel}
          variant="ghost"
        >
          Ctrl + Alt + Del
        </Button>
        <Button
          leftIcon={<FaExpandArrowsAlt />}
          onClick={handleFullScreen}
          variant="ghost"
        >
          Full Screen
        </Button>
      </Flex>
      <div
        ref={wmksContainer}
        id="wmksContainer"
        style={{
          height: "calc(100vh - 156px)",
          position: "absolute",
          width: "100vw"
        }}
      /> 
    </Box>
  )
}

export default MachineView;
