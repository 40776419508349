/**
 * Import UI components from Chakra.
 */
import { Box, Flex } from '@chakra-ui/react';

/**
 * MainLayout is the main box that floats below the navigation, which
 * will usually contain either a form or a table of some kind.
 */
const MainLayout = ({ width, children }) => {
  return (
    <Box
      padding={4}
    >
      <Flex
        backgroundColor="white"
        borderColor="brand.400"
        borderRadius="lg"
        borderWidth={4}
        boxShadow="xl"
        flexDirection="column"
        gap={4}
        marginX="auto"
        marginY={4}
        padding={8}
        position="relative"
        width={width}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default MainLayout;
