/**
 * Import hooks from React, Redux, Okta libraries.
 */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

// Import UI components from Chakra, React, and local.
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  VStack
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Error from '../common/Error';
import Loading from '../common/Loading';
import MainLayout from '../common/MainLayout';
import Message from '../Message';

// Import thunks and selectors from state slices.
import { login, selectStatus } from './authSlice';

const AuthForm = () => {
  const [showPassphrase, setShowPassphrase] = useState(false)

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const isLoading = useSelector(selectStatus) === 'loading';

  const { authState, oktaAuth } = useOktaAuth();

  const navigate = useNavigate();

  const handleShowClick = () => setShowPassphrase(!showPassphrase)
  const handleAdminLoginClick = () => {
    oktaAuth.signInWithRedirect();
    navigate('/courses');
  }

  // The useForm hook handles form state and validation.
  const {
    handleSubmit,
    register,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm();

  // The useForm hook uses handleSubmit, then calls onSubmit.
  const onSubmit = async (authData) => {
    if (authData.email.indexOf('@') === -1) {
      authData.email = authData.email + '@forescout.academy';
    }

    try {
      await dispatch(login(authData)).unwrap();
      navigate('/machines');
    } catch (err) {
      console.error(err.message);
    }
  }

  if (auth.email !== '') {
    return (
      <Message>
        You're already signed in as <strong>{auth.email}</strong>.
      </Message>
    )
  }

  if (authState?.isAuthenticated) {
    return (
      <Message>
        You're already signed in as an instructor.
      </Message>
    )
  }

  return (
    <MainLayout width="lg">
      {isLoading && <Loading message="Logging in" />}
      <Heading as="h2" size="lg" textAlign="center">Student Login</Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          {auth.error && <Error message={auth.error} />}

          <FormControl isInvalid={errors.email}>
            <FormLabel htmlFor="email">Username</FormLabel>
            <Input
              id="email"
              type="text"
              {...register('email', { required: 'Email is required.' })}
            />
            <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.passphrase}>
            <FormLabel htmlFor="passphrase">Passphrase</FormLabel>
            <InputGroup>
              <Input
                id="passphrase"
                type={showPassphrase ? "text" : "password"}
                {...register('passphrase', { required: 'Passphrase is required.' })}
              />
              <InputRightElement>
                <Button
                  colorScheme="gray"
                  onClick={handleShowClick}
                  right={1}
                  size="sm"
                >
                  {showPassphrase ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.passphrase && errors.passphrase.message}</FormErrorMessage>
          </FormControl>

          <Button
            colorScheme="brand"
            isLoading={isSubmitting}
            width="100%"
            textTransform="uppercase"
            type="submit"
          >
            Log in
          </Button>

          <Spacer />

          <Button
            colorScheme="gray"
            onClick={handleAdminLoginClick}
            variant="link"
            textDecoration="underline"
            _hover={{ color: "brand.300" }}
          >
            Instructor Login
          </Button>
        </VStack>
      </form>
    </MainLayout>
  );
}

export default AuthForm;
