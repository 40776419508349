// Import hooks from React, Redux, Okta libraries.
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

// Import UI components from Chakra, React, and local.
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  VStack
} from '@chakra-ui/react';
import Loading from '../common/Loading';
import MainLayout from '../common/MainLayout';

// Import thunks and selectors from state slices.
import {
  createCourse,
  editCourse,
  selectCourseError,
  selectCurrentCourse
} from './courseSlice';

// Import API functions from courseAPI.
import courseAPI from './courseAPI';
import { useEffect } from 'react';

const STATUS_OPTIONS = [
  'Active',
  'Archived'
];

const CourseForm = () => {
  /**
   * If there is a currently loaded course, then the form
   * is editing that course, instead of making a new one.
   */
  const currentCourse = useSelector(selectCurrentCourse);
  const inEditMode = typeof currentCourse?.id !== 'undefined';

  // Keep track of any errors in the API request.
  const requestError = useSelector(selectCourseError);

  /**
   * Hooks for the router and authentication.
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const token = authState?.accessToken.accessToken;

  /**
   * The useForm hook takes care of controlling the state of each
   * form input, as well as error validation.
   */
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm();

  /**
   * The folder and vms select dropdowns have their options populated
   * dynamically. The vms
   */
  const [foldersData, setFoldersData] = useState([]);
  const folder = watch('folder');
  let vmsData = [];
  
  if (foldersData?.length > 0 && folder !== '') {
    vmsData = foldersData.find(f => f.folder === folder).vms;
  }

  /**
   * Call backend to load in select options for both the folder dropdown
   * and the VMs dropdown.
   */
  useEffect(() => {
    async function fetchFoldersData() {
      const foldersData = await courseAPI.getAllFolders(token);
      setFoldersData(foldersData.filter(f => !f.name.includes('@') && f.vms.length > 0));
    }
    fetchFoldersData();
  }, []);

  /**
   * Set default values for the form if there is a current course
   * being edited (as opposed to a brand new course). This hook should
   * only happen when the other hook that fetches all the folders data
   * is finished.
   */
  useEffect(() => {
    if (inEditMode && foldersData.length > 0) {
      const courseFields = ['name', 'description', 'folder', 'status'];
      courseFields.forEach(f => setValue(f, currentCourse[f]));
      setValue('vms', []); // Completely clear the VMs values.
    }
  }, [foldersData]);

  /**
   * The handleSubmit, from the useForm hook, will deal with validation
   * before calling onSubmit.
   */
  const onSubmit = async (courseData) => {
    try {
      if (inEditMode) {
        const courseId = currentCourse.id;
        await dispatch(editCourse({ courseId, courseData, token })).unwrap();
      } else {
        await dispatch(createCourse({ courseData, token })).unwrap();
      }
      navigate('/courses');
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <MainLayout width="lg">
      {(foldersData.length === 0) && <Loading message="Loading folders data" />}
      <Heading as="h2" size="lg" textAlign="center">
        {inEditMode ? 'Edit' : 'New'} Course
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          {requestError && (
            <Alert
              backgroundColor="orange.500"
              borderRadius="lg"
              color="white"
              status="error"
            >
              <AlertIcon color="white" />
              {requestError}
            </Alert>
          )}

          <FormControl isInvalid={errors.name}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              type="text"
              {...register('name', { required: 'Name is required.' })}
            />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.description}>
            <FormLabel htmlFor="description">Description</FormLabel>
            <Input
              id="description"
              type="text"
              {...register('description', { required: 'Description is required.' })}
            />
            <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.folder}>
            <FormLabel htmlFor="folder">Folder</FormLabel>
            <Select
              id="folder"
              placeholder="Select folder"
              {...register('folder', { required: 'Please select a folder option.' })}
            >
              {foldersData.map(f => <option key={f.folder} value={f.folder}>{f.name}</option>)}
            </Select>
            <FormErrorMessage>{errors.folder && errors.folder.message}</FormErrorMessage>
          </FormControl>

          {vmsData.length > 0 &&
            <FormControl isInvalid={errors.vms}>
              <FormLabel htmlFor="vms">VMs</FormLabel>
              <Select
                height=""
                iconColor="white"
                id="vms"
                multiple
                {...register('vms', { required: 'Please select at least one VM.'})}
              >
                {vmsData.map(v => (
                  <option
                    key={v.vm}
                    style={{margin: "8px 0"}}
                    value={v.vm}
                  >
                    {v.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.vms && errors.vms.message}</FormErrorMessage>
            </FormControl>
          }

          <FormControl isInvalid={errors.status}>
            <FormLabel htmlFor="status">Status</FormLabel>
            <Select
              id="status"
              placeholder="Select status"
              {...register('status', { required: 'Please select a status option.'})}
            >
              {STATUS_OPTIONS.map(s => <option key={s} value={s.toUpperCase()}>{s}</option>)}
            </Select>
            <FormErrorMessage>{errors.status && errors.status.message}</FormErrorMessage>
          </FormControl>

          <Button
            colorScheme="brand"
            isLoading={isSubmitting}
            width="100%"
            textTransform="uppercase"
            type="submit"
          >
            {inEditMode ? 'Save Changes' : 'Create Course'}
          </Button>
        </VStack>
      </form>
    </MainLayout>
  );
};

export default CourseForm;
