// Import hooks from React, Redux, Okta libraries.
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

// Import UI components from Chakra, React, and local.
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import Loading from '../common/Loading';
import MainLayout from '../common/MainLayout';

// Import thunks and selectors from state slices.
import {
  createClass,
  editClass,
  selectClassError,
  selectCurrentClass
} from './classSlice';

// Import API functions from classAPI.
import courseAPI from '../course/courseAPI';
import { useEffect } from 'react';

/**
  * Converts a time zone string into the correct offset.
  * @param {string} timeZone - standard time zone like "America/New_York".
  * @returns {string} - offset with sign like "-04:30".
  */
const getOffsetString = (timeZone) => {
  const systemDateTime = new Date();
  systemDateTime.setMilliseconds(0);

  // Make another datetime based on the time zone passed in.
  const systemOffset = systemDateTime.getTimezoneOffset() / 60 * -1;
  const timeZoneLocale = systemDateTime.toLocaleString('en-US', { timeZone });
  const timeZoneDateTime = new Date(timeZoneLocale);

  // Get the integer difference between the system date time and the other one.
  const differenceInHours = (timeZoneDateTime.getTime() - systemDateTime.getTime()) / 1000 / 60 / 60;
  let timeZoneOffset = systemOffset + differenceInHours;

  // Start building the offset string with a sign first.
  let offsetHours = timeZoneOffset < 0 ? '-' : '+';

  // Pad an extra 0 if necessary.
  if (Math.abs(timeZoneOffset) < 10) {
    offsetHours = offsetHours + '0';
  }

  // Now tack on the actual number difference.
  offsetHours = offsetHours + Math.abs(Math.trunc(timeZoneOffset));

  // Handle any offsets that are not whole number hours, such as 5.75 for Nepal.
  let offsetMinutes = (timeZoneOffset % 1).toFixed(2).substring(2);
  offsetMinutes = parseInt(offsetMinutes) * 60 / 100;

  // Pad an extra 0 if necessary.
  if (offsetMinutes < 10) {
    offsetMinutes = '0' + offsetMinutes;
  }

  return offsetHours + ':' + offsetMinutes;
}

/**
  * Converts a date into the preferred format for a date type input.
  * @param {Date} fullDate - JavaScript Date object.
  * @returns {string} - simplified date like "2022-08-28".
  */
const getDateInputString = (fullDate) => {
  // Do a little extra work to get the month and day right for endDateString.
  let month = fullDate.getUTCMonth() + 1;
  let date = fullDate.getUTCDate();

  if (month < 10) month = '0' + month;
  if (date < 10) date = '0' + date;

  return fullDate.getUTCFullYear() + '-' + month + '-' + date;
}

const ClassForm = () => {
  // Check for a currently loaded course and set to edit mode.
  const currentClass = useSelector(selectCurrentClass);
  const inEditMode = typeof currentClass?.id !== 'undefined';

  // Keep track of any errors in the API requests.
  const requestError = useSelector(selectClassError);

  // Hooks for the router and authentication.
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const token = authState?.accessToken.accessToken;

  // The useForm hooks handles form state and validation.
  const {
    handleSubmit,
    register,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm();

  // Variables for the select dropdown options.
  const [coursesData, setCoursesData] = useState([]);
  const attendeesOptions = Array.from(Array(15).keys()).map(n => n + 1);

  // Call backend to load in select options for the course dropdown.
  useEffect(() => {
    async function fetchCoursesData() {
      const coursesData = await courseAPI.getAllCourses(token);
      setCoursesData(coursesData);
    }
    fetchCoursesData();
  }, []);

  // Set the default time zone to the user's system.
  useEffect(() => {
    setValue('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [])

  /**
   * Set default values for the form if there is a current class
   * being edited (as opposed to a brand new class).
   */
  useEffect(() => {
    if (inEditMode && coursesData.length > 0) {
      // Set the dates into the format that input type date prefers: 2022-08-08 
      setValue('startDate', getDateInputString(new Date(currentClass.startDate)));
      setValue('endDate', getDateInputString(new Date(currentClass.endDate)));

      // Set the remaining values too.
      const classFields = ['name', 'courseId', 'instructor', 'attendees'];
      classFields.forEach(f => setValue(f, currentClass[f]));
    }
  }, [coursesData]);

  /**
   * The handleSubmit, from the useForm hook, will deal with validation
   * before calling onSubmit. Get the date strings into the proper format
   * for the API request.
   */
  const onSubmit = async (classData) => {
    // Add an extra day to the end date.
    let endDate = new Date(classData.endDate);
    endDate.setDate(endDate.getDate() + 1);

    // Build the date strings, with midnight and the selected time zone.
    const offset = getOffsetString(classData.timeZone);
    const startDateString = classData.startDate + 'T00:00:00' + offset;
    const endDateString = getDateInputString(endDate) + 'T00:00:00' + offset;

    // Switch the dates from their time zone to UTC for the backend.
    classData = {
      ...classData,
      startDate: new Date(startDateString).toISOString(),
      endDate: new Date(endDateString).toISOString()
    };

    try {
      if (inEditMode) {
        const classId = currentClass.id;
        await dispatch(editClass({ classId, classData, token })).unwrap();
      } else {
        await dispatch(createClass({ classData, token })).unwrap();
      }
      navigate('/classes');
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <MainLayout width="lg">
      {(coursesData.length === 0) && <Loading message="Loading courses data" />}
      <Heading as="h2" size="lg" textAlign="center">
        {inEditMode ? 'Edit' : 'New'} Class
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          {requestError && (
            <Alert
              backgroundColor="orange.500"
              borderRadius="lg"
              color="white"
              status="error"
            >
              <AlertIcon color="white" />
              {requestError}
            </Alert>
          )}

          <FormControl isInvalid={errors.name}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              type="text"
              {...register('name', { required: 'Name is required.'})}
            />
            <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.courseId}>
            <FormLabel htmlFor="courseId">Course</FormLabel>
            <Select
              id="courseId"
              placeholder="Select course"
              {...register('courseId', { required: 'Please select a course option.' })}
            >
              {coursesData.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
            </Select>
            <FormErrorMessage>{errors.courseId && errors.courseId.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.timeZone}>
            <FormLabel htmlFor="timeZone">Time Zone</FormLabel>
            <Select
              id="timeZone"
              {...register('timeZone', { required: 'Please select a time zone.' })}
            >
              {Intl.supportedValuesOf('timeZone').map(z => (
                <option key={z} value={z}>{z}</option>
              ))}
            </Select>
            <FormErrorMessage>{errors.timeZone && errors.timeZone.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.startDate}>
            <FormLabel htmlFor="startDate">First Day of Class</FormLabel>
            <Input
              id="startDate"
              type="date"
              {...register('startDate', { required: 'Please select the first day of class.' })}
            />
            <FormErrorMessage>{errors.startDate && errors.startDate.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.endDate}>
            <FormLabel htmlFor="endDate">Last Day of Class</FormLabel>
            <Input
              id="endDate"
              type="date"
              {...register('endDate', { required: 'Please select the last day of class.' })}
            />
            <FormErrorMessage>{errors.endDate && errors.endDate.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.instructor}>
            <FormLabel htmlFor="instructor">Instructor Email</FormLabel>
            <Input
              id="instructor"
              type="email"
              {...register('instructor', {
                required: 'Instructor email is required.',
                pattern: {
                  value: /^(.+)@(.+)$/i,
                  message: 'Please check the email format.'
                }
              })}
            />
            <FormErrorMessage>{errors.instructor && errors.instructor.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.attendees}>
            <FormLabel htmlFor="attendees">Attendees</FormLabel>
            <Select
              id="attendees"
              {...register('attendees', { required: 'Attendees is required.' })}
            >
              {attendeesOptions.map(n => <option key={n} value={n}>{n}</option>)}
            </Select>
            <FormErrorMessage>{errors.attendees && errors.attendees.message}</FormErrorMessage>
            <FormHelperText color="gray.400">
              Please note the class will be provisioned one day before the first day
              and shut down at the end of the last day.
            </FormHelperText>
          </FormControl>

          <Button
            colorScheme="brand"
            isLoading={isSubmitting}
            width="100%"
            textTransform="uppercase"
            type="submit"
          >
            {inEditMode ? 'Save Changes' : 'Create Class'}
          </Button>
        </VStack>
      </form>
    </MainLayout>
  );
};

export default ClassForm;
