import {
  Box,
  Tooltip
} from '@chakra-ui/react';

const COLORS = {
  'POWERED_OFF': 'red',
  'COMPLETED': 'red',
  'ARCHIVED': 'red',
  'SUSPEND': 'yellow',
  'SUSPENDED': 'yellow',
  'SCHEDULED': 'yellow',
  'POWERED_ON': 'green',
  'RUNNING': 'green',
  'ACTIVE': 'green'
};

const StatusIndicator = ({ status }) => {
  return (
    <Tooltip label={status.replace(/_/g, ' ')}>
      <Box
        backgroundColor={COLORS[status] || 'red'}
        borderRadius="50%"
        height={4}
        margin="0 auto"
        width={4}
      >
        &nbsp;
      </Box>
    </Tooltip>
  );
};

export default StatusIndicator;
