import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useOktaAuth } from '@okta/okta-react';
import {
  Icon,
  Flex,
  Link,
  Text
} from '@chakra-ui/react'
import {
  FaSignInAlt,
  FaSignOutAlt
} from 'react-icons/fa'
import { logout, selectEmail } from './authSlice'
import { clearList, completeRevert } from '../machine/machineSlice'

function AuthLink() {
  const email = useSelector(selectEmail)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { authState, oktaAuth } = useOktaAuth();

  const handleLogoutClick = () => {
    if (authState?.isAuthenticated) {
      oktaAuth.signOut();
    } else {
      dispatch(logout())
      dispatch(clearList())
      dispatch(completeRevert())
      navigate('/')
    }
  }

  if (!email && (!authState || !authState.isAuthenticated)) {
    return (
      <Link
        as={ReactRouterLink}
        colorScheme="white"
        fontWeight={600}
        textTransform="uppercase"
        to="/login"
        variant="button"
      >
        <Flex alignItems="center">
          <Text>Log In</Text>
          <Icon as={FaSignInAlt} marginLeft={2} />
        </Flex>
      </Link>
    )
  }

  return (
    <Link
      colorScheme="white"
      fontWeight={600}
      onClick={handleLogoutClick}
      textTransform="uppercase"
      variant="button"
    >
      <Flex alignItems="center">
        <Text>Log Out</Text>
        <Icon as={FaSignOutAlt} marginLeft={2} />
      </Flex>
    </Link>
  );
}

export default AuthLink;
