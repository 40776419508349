const machineAPI = {
  /**
   * This calls a certain power endpoint depending on the command parameter.
   * There is also a sanity check on the machine's current state. Don't bother
   * with a call if the machine is unavailable, or in a contradictory state.
   * @param {Object} - contains virtual machine id, and the command to execute
   * @param {string} basic - base64 authentication
   * @returns {Object} - virtual machine data
   * @throws {Error} - if the request fails or the machine command cannot be performed.
   */
  async power({ machineId, command }, basic) {
    // Send out a quick refresh request to get the latest status of the machine.
    const refreshData = await this.refresh({ machineId }, basic);

    // In the middle of a suspension, don't let anything happen.
    if (refreshData.powerState === 'SUSPEND') {
      throw new Error('Machine is in SUSPEND state. Please wait to try again.');
    }

    const poweredOnCommands = ['start'];
    if (refreshData.powerState === 'POWERED_ON' && poweredOnCommands.includes(command)) {
      throw new Error('Machine is already in POWERED_ON state.');
    }

    const poweredOffCommands = ['reset', 'stop', 'shutdown'];
    if (refreshData.powerState === 'POWERED_OFF' && poweredOffCommands.includes(command)) {
      throw new Error('Machine is already in POWERED_OFF state.');
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class/vm/${machineId}/power/${command}`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Authorization': `Basic ${basic}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 400 && response.status < 500) {
      throw new Error('Request contains an invalid virtual machine or command.');
    }

    if (response.status >= 500) {
      throw new Error('Server is unavailable.');
    }

    const data = await response.json();

    if (command === 'shutdown') {
      data.powerState = 'POWERED_OFF';
    }

    return data;
  },

  async refresh({ machineId }, basic) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class/vm/${machineId}/power`, {
        method: 'GET',
        headers: {
          Accept: '*/*',
          'Authorization': `Basic ${basic}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 400 && response.status < 500) {
      throw new Error('Request contains an invalid virtual machine.');
    }

    if (response.status >= 500) {
      throw new Error('Server is unavailable.');
    }

    const data = await response.json();

    return data;
  },

  /**
   * Gets a unique ticket id to use to connect to the machine with VMware library
   * code in MachineView component.
   * 
   * @param {Object} - contains virtual machine id, and the command to execute
   * @param {string} basic - base64 authentication
   * 
   * @returns 
   */
  async ticket({ machineId }, basic) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class/vm/${machineId}/ticket`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Authorization': `Basic ${basic}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 400 && response.status < 500) {
      throw new Error('Request contains an invalid virtual machine.');
    }

    if (response.status >= 500) {
      throw new Error('Server is unavailable.');
    }

    const data = await response.json();

    return data;
  },

  async revert(basic) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class/revert`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Authorization': `Basic ${basic}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  }
}

export default machineAPI;
