import {
  Button
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { power, refresh, ticket } from './machineSlice';

function MachineAction({ color, command, icon, machineId, text }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async () => {
    switch (command) {
      case 'view':
        dispatch(ticket({ machineId }));
        navigate('/view');
        break;
      case 'refresh':
        dispatch(refresh({ machineId }));
        break;
      default:
        dispatch(power({ machineId, command }));
    }
  }

  return (
    <Button
      colorScheme={color}
      onClick={handleClick}
      size="sm"
      leftIcon={icon}
      variant="solid"
    >
      {text}
    </Button>
  )
}

export default MachineAction;
