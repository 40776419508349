import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from './SecureRoute';
import ClassForm from './class/ClassForm';
import ClassTable from './class/ClassTable';
import CourseForm from './course/CourseForm';
import CourseTable from './course/CourseTable';
import AuthForm from './auth/AuthForm';
import MachineView from './machine/MachineView';
import MachineTable from './machine/MachineTable';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthForm />} />
      <Route path='/login/callback' element={<LoginCallback />} />
      <Route path="/login" element={<AuthForm />} />
      <Route path="/view" element={<MachineView />} />
      <Route path="/machines" element={<MachineTable />} />
      <Route path='/courses' element={<RequiredAuth />}>
        <Route path='' element={<CourseTable />} />
        <Route path='new' element={<CourseForm />} />
        <Route path='edit' element={<CourseForm />} />
      </Route>
      <Route path='/classes' element={<RequiredAuth />}>
        <Route path='' element={<ClassTable />} />
        <Route path='new' element={<ClassForm />} />
        <Route path='edit' element={<ClassForm />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
