const authAPI = {
  /**
   * Login as a student with personal email address and class passphrase.
   * @param {Object} loginData - containing email and passphrase.
   * @returns {Object} - class info and virtual machine list.
   * @throws {Error} - on request failure.
   */
  async login({ email, passphrase }) {
    /**
     * Create the basic auth encoding. The btoa method is discontinued.
     * const basic = Buffer.from(`${email}:${passphrase}`, 'base64');
     */
    const basic = btoa(`${email}:${passphrase}`);
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class/login`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Authorization': `Basic ${basic}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('You have entered an invalid email or passphrase.');
    }

    const data = await response.json();

    // Return the login data, but also the original parameters.
    return {
      ...data,
      email,
      passphrase,
      basic
    }
  }
};

export default authAPI;
