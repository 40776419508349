const courseAPI = {
  /**
   * Fetches all of the courses currently set up in the backend.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @returns {Array} - list of all courses as Objects with all kinds of info.
   * @throws {Error} - if the request comes back with a status code in the 400s or 500s.
   */
  async getAllCourses(token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-course`, {
        method: 'GET',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 500) {
      throw new Error('Trouble fetching course list from the server.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  },

  async getAllFolders(token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-course/folder`, {
        method: 'GET',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  },

  /**
   * Creates one course in the backend.
   * @param {Object} payload - full Object of course information.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @throws {Error} - if the request comes back with a status code in the 400s or 500s.
   */
  async postCourse(payload, token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-course`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  },

  /**
   * Edits one course in the backend.
   * @param {string} courseId - id of the course to be edited.
   * @param {Object} payload - full Object of course information.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @throws {Error} - if the request comes back with a status code in the 400s or 500s.
   */
  async putCourse(courseId, payload, token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-course/${courseId}`, {
        method: 'PUT',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  },


  /**
   * Deletes one course from backend.
   * @param {string} courseId - id of the course to be deleted.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @returns {Error} - if the request comes back with a status code in the 400s or 500s.
   */
  async deleteCourse(courseId, token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-course/${courseId}`, {
        method: 'DELETE',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    return { courseId };
  }
};

export default courseAPI;
