import {
  Box,
  Flex,
  Heading,
  Image,
} from '@chakra-ui/react'

function Logo() {
  return (
    <Box flexGrow={1}>
      <Flex alignItems="center">
        <Image
          alt="Forescout logo"
          htmlHeight="32px"
          htmlWidth="256px"
          src="/forescout-logo-white.svg"
        />
        <Heading
          as="h1"
          fontSize="xl"
          marginLeft={4}
        >
          Instructor Led Training
        </Heading>
      </Flex>
    </Box>
  )
}

export default Logo