import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';

function Message({ children }) {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      minHeight="calc(100vh - 100px)"
      justifyContent="space-around"
    >
      <Box
        backgroundColor="white"
        borderColor="brand.400"
        borderRadius="lg"
        borderWidth={2}
        padding={8}
        textAlign="center"
        width="md"
      >
        <Text
          fontSize="xl"
        >
          {children}
        </Text>
      </Box>
    </Flex>
  )
}

export default Message;
