import { Table, TableContainer } from '@chakra-ui/react';

const TableLayout = ({ children }) => {
  return (
    <TableContainer
      borderColor="gray.100"
      borderWidth={2}
      width="100%"
    >
      <Table colorScheme="black" size="sm" variant="striped">
        {children}
      </Table>
    </TableContainer>
  );
}

export default TableLayout;
