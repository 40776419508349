import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import {
  FaBolt,
  FaHandPaper,
  FaPowerOff,
  FaRedoAlt,
  FaSyncAlt,
} from 'react-icons/fa';

function ActionModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Action Definitions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection="column" gap={2}>
            <Flex alignItems="center">
              <Button
                colorScheme="brand"
                leftIcon={<FaRedoAlt />}
                marginRight={4}
              >
                Reboot
              </Button>
              <Text>
                Restart OS, close all apps and processes
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Button
                colorScheme="brand"
                leftIcon={<FaRedoAlt />}
                marginRight={4}
              >
                Refresh
              </Button>
              <Text>
                Get an update of the virtual machine's state
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Button
                colorScheme="brand"
                leftIcon={<FaSyncAlt />}
                marginRight={4}
              >
                Reset
              </Button>
              <Text>
                Hard restart with machine power button
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Button
                colorScheme="orange"
                leftIcon={<FaPowerOff />}
                marginRight={4}
              >
                Shut down
              </Button>
              <Text>
                Shut down OS
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Button
                colorScheme="darkBlue"
                leftIcon={<FaBolt />}
                marginRight={4}
              >
                Start
              </Button>
              <Text>
                Power on virtual machine
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Button
                colorScheme="darkBlue"
                leftIcon={<FaHandPaper />}
                marginRight={4}
              >
                Stop
              </Button>
              <Text>
                Power off virtual machine
              </Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter />
      </ModalContent>

    </Modal>
  )
}

export default ActionModal;
