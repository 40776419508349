import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authAPI from './authAPI';
import { buildList } from './../machine/machineSlice';
import { loadCurrentClass } from './../class/classSlice';

/**
 * Thunk to trigger the login API call and then build the machine
 * list with the returned data.
 */
export const login = createAsyncThunk('auth/login',
  async (loginData, thunkAPI) => {
    try {
      const data = await authAPI.login(loginData);
      await thunkAPI.dispatch(loadCurrentClass({ class: data.trainingClass }));
      await thunkAPI.dispatch(buildList({ machines: data.vms, expectedVmCount: data.expectedVmCount }));
      return data
    } catch(err) {
      return thunkAPI.rejectWithValue({ message: err.message })
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    status: 'idle',
    email: '',
    passphrase: '',
    basic: '',
    error: null
  },
  reducers: {
    logout: (state) => {
      return {
        ...state,
        email: '',
        passphrase: '',
        basic: ''
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.email = action.payload.email;
        state.passphrase = action.payload.passphrase;
        state.basic = action.payload.basic;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.basic = '';
        state.error = action.payload.message;
      })
  }
})

export const selectEmail = state => state.auth.email;
export const selectPassphrase = state => state.auth.passphrase;
export const selectStatus = state => state.auth.status;

export const { loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;
