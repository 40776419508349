// Import hooks from React, Redux, Okta libraries.
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

// Import UI components from Chakra, React, and local.
import {
  Button,
  Flex,
  Heading,
  Icon,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import {
  FaEdit,
  FaPlus,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTrash
} from 'react-icons/fa';
import ConfirmationModal from '../common/ConfirmationModal';
import Error from '../common/Error';
import Loading from '../common/Loading';
import MainLayout from '../common/MainLayout';
import StatusIndicator from '../common/StatusIndicator';
import TableLayout from '../common/TableLayout';

// Import thunks and selectors from state slices.
import {
  clearCurrent,
  deleteCourse,
  fetchAllCourses,
  loadCurrent,
  selectCourseError,
  selectCourseList
} from './courseSlice';

const TABLE_COLUMNS = [
  {
    name: 'Status',
    key: 'status'
  },
  {
    name: 'Name',
    key: 'name'
  },
  {
    name: 'Description',
    key: null
  },
  {
    name: 'Folder',
    key: null
  },
  {
    name: 'Actions',
    key: null
  }
];

/**
 * CourseTable displays a heading and all courses with their respective
 * information like description and status.
 */
const CourseTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const token = authState?.accessToken.accessToken;
  const error = useSelector(selectCourseError);
  const courseList = useSelector(selectCourseList);
  const [courseToDelete, setCourseToDelete] = useState({});

  // Hook for delete confirmation modal.
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose
  } = useDisclosure();

  // State for the table data column sorting.
  const [improvedCourseList, setImprovedCourseList] = useState([]);
  const [sortColumn, setSortColumn] = useState('name');
  const [isAscending, setIsAscending] = useState(true);

  // Get all the courses when the component loads.
  useEffect(() => {
    dispatch(fetchAllCourses({ token }));
  }, []);

  /**
   * Sort the table whenever the sortColumn, isAscending,
   * or courseList changes.
   */
  useEffect(() => {
    const newList = [...courseList].sort((a, b) => {
      if (isAscending) {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    });

    setImprovedCourseList(newList);
  }, [courseList, sortColumn, isAscending])

  const handleNew = () => {
    dispatch(clearCurrent());
    navigate('/courses/new');
  }

  const handleEdit = (courseData) => {
    dispatch(loadCurrent({ course: courseData }));
    navigate('/courses/edit');
  }

  const handleDelete = () => {
    const courseId = courseToDelete.id;
    onConfirmationClose();
    dispatch(deleteCourse({ courseId, token }));
  }

  const handleSortChange = (key) => {
    const newAscending = key === sortColumn ? !isAscending : true;
    setSortColumn(key);
    setIsAscending(newAscending);
  }

  // Reusable table row for the columns headings.
  const tableLabelRow =
    <Tr>
      {TABLE_COLUMNS.map(column => {
        let sortIcon = FaSort;

        if (sortColumn === column.key) {
          sortIcon = isAscending ? FaSortDown : FaSortUp;
        }

        return (
          <Th key={column.name}>
            <Flex gap={2} alignItems="center">
              <Text>{column.name}</Text>
              {column.key && <Icon as={sortIcon} onClick={() => handleSortChange(column.key)} _hover={{ cursor: 'pointer' }} />}
            </Flex>
          </Th>
        );
      })}
    </Tr>;

  return (
    <MainLayout>
      {(courseList.length === 0) && (error === null) && <Loading message="Loading courses" />}
      {(courseList.length === 0) && error && <Error message={error} />}

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        handleAction={handleDelete}
        header="Delete Course"
        message="Are you sure? This will permanently erase this course."
        actionText="Delete"
      />

      <Flex alignItems="flex-end" justifyContent="space-between">
        <Heading as="h2" size="lg">Courses</Heading>
        <Button
          colorScheme="brand"
          leftIcon={<FaPlus />}
          onClick={handleNew}
          variant="solid"
        >
          Add Course
        </Button>
      </Flex>
      <TableLayout>
        <Thead>{tableLabelRow}</Thead>
        <Tbody>
          {improvedCourseList.map(c => (
            <Tr key={c.id}>
              <Td><StatusIndicator status={c.status} /></Td>
              <Td>{c.name}</Td>
              <Td>{c.description}</Td>
              <Td>{c.folder}</Td>
              <Td>
                <Flex gap={2}>
                  <Button
                    colorScheme="darkBlue"
                    leftIcon={<FaEdit />}
                    onClick={() => handleEdit(c)}
                    size="xs"
                    variant="solid"
                  >
                    Edit
                  </Button>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>{tableLabelRow}</Tfoot>
      </TableLayout>
    </MainLayout>
  );
};

export default CourseTable;
