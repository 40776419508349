import {
  Link as ReactRouterLink,
} from 'react-router-dom'
import {
  Flex, Text, Link
} from '@chakra-ui/react'

function NavItem({ href, text, icon }) {
  return (
    <Link
      as={ReactRouterLink}
      fontWeight={600}
      textTransform="uppercase"
      to={href}
    >
      <Flex alignItems="center">
        {icon}
        <Text marginLeft={2}>{text}</Text>
      </Flex>
    </Link>
  )
}

export default NavItem