import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react';

function Loading({ message }) {
  const [dots, setDots] = useState('...');

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDots(prevDots => prevDots.length === 3 ? '' : prevDots + '.');
    }, 500);

    return () => clearInterval(dotInterval);
  }, []);

  return (
    <Box
      backgroundColor="rgba(255, 255, 255, 0.85)"
      borderRadius="lg"
      bottom="0"
      height="100%"
      left="0"
      position="absolute"
      right="0"
      top="0"
      width="100%"
      zIndex="999"
    >
      <Flex
        alignItems="center"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <Text
          fontSize="3xl"
          fontWeight="bold"
        >
          {message}
        </Text>
        <Text
          fontSize="3xl"
          fontWeight="bold"
          width="3ch"
        >
          {dots}
        </Text>
      </Flex>
    </Box>
  )
}

export default Loading;
