import { Box, Text } from '@chakra-ui/react';

const Footer = () => {
  const footerText = process.env.REACT_APP_VERSION_NUMBER || 'dev';

  return (
    <Box
      backgroundColor="gray.400"
      color="white"
      paddingX={8}
      paddingY={2}
      textAlign="right"
    >
      <Text>{footerText}</Text>
    </Box>
  )
}

export default Footer;
