// Import hooks from React, Redux, Okta libraries.
import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Import UI components from Chakra, React, and local.
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Link,
  Tbody,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import {
  FaFastBackward,
  FaHandPaper,
  FaPowerOff,
  FaQuestion,
  FaRedoAlt
} from 'react-icons/fa';
import { Link as ReactRouterLink } from 'react-router-dom';
import ActionModal from '../ActionModal'
import ConfirmationModal from '../common/ConfirmationModal';
import Loading from '../common/Loading';
import MachineRow from './MachineRow';
import MainLayout from '../common/MainLayout';
import Message from '../Message';
import TableLayout from '../common/TableLayout';

// Import thunks and selectors from state slices.
import { login, selectEmail, selectPassphrase } from '../auth/authSlice';
import { selectName } from '../class/classSlice';
import {
  selectList,
  selectExpectedVmCount,
  selectStatus,
  selectIsReverting,
  power,
  revert,
  completeRevert
} from './machineSlice';

const MachineTable = () => {
  const machineList = useSelector(selectList);
  const poweredOnMachineList = useMemo(() => machineList.filter(m => m.power_state === 'POWERED_ON'), [machineList]);
  const className = useSelector(selectName);
  const {
    isOpen: isActionOpen,
    onOpen: onActionOpen,
    onClose: onActionClose
  } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose
  } = useDisclosure();
  const email = useSelector(selectEmail);
  const passphrase = useSelector(selectPassphrase);
  const expectedVmCount = useSelector(selectExpectedVmCount);

  const dispatch = useDispatch();
  const isLoading = useSelector(selectStatus) === 'loading';
  const isReverting = useSelector(selectIsReverting);

  useEffect(() => {
    let provisionTimer;

    if (isReverting && (expectedVmCount !== poweredOnMachineList.length)) {
      provisionTimer = setTimeout(() => {
        dispatch(login({ email, passphrase }));
      }, 25000);
    } else {
      dispatch(completeRevert());
    }

    return () => clearTimeout(provisionTimer);
  }, [expectedVmCount, machineList]);

  if (passphrase === '' && expectedVmCount === 0) {
    return (
      <Message>
        Please <Link color="brand.600" as={ReactRouterLink} to="/login">log in</Link> to view your machine list.
      </Message>
    )
  }

  const handleRefresh = () => {
    dispatch(login({ email, passphrase }));
  }

  const handlePowerAll = (powerOn) => {
    const desiredState = powerOn ? 'POWERED_ON' : 'POWERED_OFF';
    const command = powerOn ? 'start' : 'stop';

    for (const machine of machineList) {
      if (machine.power_state !== desiredState) {
        const machineId = machine.vm;
        dispatch(power({ machineId, command }));
      }
    }
  }

  const handleSuspendAll = () => {
    for (const machine of machineList) {
      if (machine.power_state !== 'SUSPEND' || machine.power_state !== 'SUSPENDED') {
        const machineId = machine.vm;
        const command = 'suspend';
        dispatch(power({ machineId, command }));
      }
    }
  }

  const handleRevert = () => {
    onConfirmationClose();
    dispatch(revert());
  };

  // Reusable table row for the column headings.
  const tableLabelRow = 
    <Tr>
      <Th>State</Th>
      <Th>Machine Name</Th>
      <Th>
        <Flex alignItems="center">
          <Text>
            Actions
          </Text>
          <IconButton
            borderRadius="50%"
            color="white"
            colorScheme="gray"
            icon={<FaQuestion />}
            isRound={true}
            marginLeft={2}
            onClick={onActionOpen}
            size="xs"
          />
        </Flex>
      </Th>
    </Tr>;

  return (
    <Box
      minHeight="calc(100vh - 100px)"
    >
      <ActionModal
        isOpen={isActionOpen}
        onClose={onActionClose}
      />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        handleAction={handleRevert}
        header="Revert Lab"
        message="Are you sure? This will erase all of the work done on all virtual machines."
        actionText="Revert"
      />
      {isReverting &&
        <Flex
          alignItems="center"
          backgroundColor="orange.300"
          justifyContent="space-evenly"
          padding={2}
        >
          <Text
            color="white"
            fontWeight="bold"
          >
            {machineList.length} / {expectedVmCount} machines provisioned.&nbsp;&nbsp;
            {poweredOnMachineList.length} / {expectedVmCount} machines powered on.&nbsp;&nbsp;
            Please stand by.
          </Text>
        </Flex>
      }
      <MainLayout>
        {(isLoading || isReverting) && <Loading message="Reverting entire lab. This may take a few minutes" />}
        <Flex alignItems="flex-end" gap={2} justifyContent="flex-end">
          <Heading as="h2" flexGrow={1} size="lg">{className}</Heading>
          <Button
            colorScheme="gray"
            leftIcon={<FaRedoAlt />}
            onClick={handleRefresh}
            variant="solid"
          >
            Refresh List
          </Button>
          <Button
            colorScheme="gray"
            leftIcon={<FaPowerOff />}
            onClick={() => handlePowerAll(true)}
            variant="solid"
          >
            Power On All
          </Button>
          <Button
            colorScheme="gray"
            leftIcon={<FaPowerOff />}
            onClick={() => handlePowerAll(false)}
            variant="solid"
          >
            Power Off All
          </Button>
          <Button
            colorScheme="gray"
            leftIcon={<FaHandPaper />}
            onClick={() => handleSuspendAll()}
            variant="solid"
          >
            Suspend All
          </Button>
          <Button
            colorScheme="orange"
            leftIcon={<FaFastBackward />}
            onClick={onConfirmationOpen}
            variant="solid"
          >
            Revert Lab
          </Button>
        </Flex>
        <TableLayout>
          <Thead>{tableLabelRow}</Thead>
          <Tbody>
            {machineList.map(m => (
              <MachineRow
                key={m.vm}
                id={m.vm}
                name={m.name}
                powerState={m.power_state}
                hasVmWare={m.vm_ware_tools}
                isLoading={m.isLoading}
                message={m.message}
              />
            ))}
          </Tbody>
          <Tfoot>{tableLabelRow}</Tfoot>
        </TableLayout>
      </MainLayout>
    </Box>
  )
}

export default MachineTable;
