const classAPI = {
  /**
   * Fetches all of the classes currently set up in backend.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @returns {Array} - list of all classes as Objects with all kinds of info.
   */
  async getAllClasses(token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class`, {
        method: 'GET',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 500) {
      throw new Error('Trouble fetching class list from the server.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  },

  /**
   * Creates one class in the backend.
   * @param {Object} payload - full Object of class information.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @throws {Error} - if the request comes back with a status code in the 400s or 500s.
   */
  async postClass(payload, token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  },

  /**
   * Edits one class in the backend.
   * @param {string} classId - id of the class to be edited.
   * @param {Object} payload - full Object of class information.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @throws {Error} - if the request comes back with a status code in the 400s or 500s.
   */
  async putClass(classId, payload, token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class/${classId}`, {
        method: 'PUT',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    const data = await response.json();

    return data;
  },


  /**
   * Deletes one class from backend.
   * @param {string} classId - id of the class to be deleted.
   * @param {string} token - the Bearer token used for authorization, from Okta.
   * @returns {Error} - if the request comes back with a status code in the 400s or 500s.
   */
  async deleteClass(classId, token) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/training-class/${classId}`, {
        method: 'DELETE',
        headers: {
          Accept: '*/*',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 500) {
      throw new Error('Server error.');
    }

    if (response.status >= 400) {
      throw new Error('Client error.');
    }

    return { classId };
  }
};

export default classAPI;
