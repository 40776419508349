import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import machineAPI from './machineAPI'

export const power = createAsyncThunk('machine/power',
  async ({ machineId, command }, thunkAPI) => {
    const basic = thunkAPI.getState().auth.basic;
    try {
      const data = await machineAPI.power({ machineId, command }, basic);
      return data;
    } catch(err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  }
);

export const refresh = createAsyncThunk('machine/refresh',
  async ({ machineId }, thunkAPI) => {
    const basic = thunkAPI.getState().auth.basic;
    try {
      const data = await machineAPI.refresh({ machineId }, basic);
      return data;
    } catch(err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  }
);

export const ticket = createAsyncThunk('machine/ticket',
  async ({ machineId }, thunkAPI) => {
    const basic = thunkAPI.getState().auth.basic;
    try {
      const data = await machineAPI.ticket({ machineId }, basic);
      return data;
    } catch(err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  }
);

export const revert = createAsyncThunk('machine/revert',
  async (arg, thunkAPI) => {
    const basic = thunkAPI.getState().auth.basic;
    try {
      const data = await machineAPI.revert(basic);
      return data;
    } catch(err) {
      return thunkAPI.rejectWithValue({ message: err.message });
    }
  }
);

export const machineSlice = createSlice({
  name: 'machine',
  initialState: {
    status: 'idle',
    list: [],
    current: {},
    error: null,
    expectedVmCount: 0,
    isReverting: false
  },
  reducers: {
    buildList: (state, action) => {
      return {
        ...state,
        list: action.payload.machines,
        expectedVmCount: action.payload.expectedVmCount
      }
    },
    clearList: (state) => {
      return {
        ...state,
        list: [],
        expectedVmCount: 0
      }
    },
    completeRevert: (state) => {
      return {
        ...state,
        isReverting: false
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(power.pending, (state, action) => {
        state.list = state.list.map(m => {
          if (action.meta.arg.machineId === m.vm) {
            return {
              ...m,
              isLoading: true,
              message: null
            }
          }

          return m;
        })
      })
      .addCase(power.fulfilled, (state, action) => {
        state.list = state.list.map(m => {
          if (action.payload.vm === m.vm) {
            return {
              ...m,
              vm: action.payload.vm,
              power_state: action.payload.powerState,
              isLoading: false,
              message: null
            }
          } 

          return m;
        });

        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(power.rejected, (state, action) => {
        state.list = state.list.map(m => {
          if (action.meta.arg.machineId === m.vm) {
            return {
              ...m,
              power_state: 'SUSPEND',
              isLoading: false,
              message: action.payload.message
            }
          }

          return m
        })

        state.status = 'failed'
        state.error = action.payload.message
      })
      .addCase(refresh.pending, (state, action) => {
        state.list = state.list.map(m => {
          if (action.meta.arg.machineId === m.vm) {
            return {
              ...m,
              isLoading: true,
              message: null
            }
          }

          return m;
        })
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.list = state.list.map(m => {
          if (action.payload.vm === m.vm) {
            return {
              ...m,
              power_state: action.payload.powerState,
              isLoading: false,
              message: null
            }
          }

          return m;
        })

        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(refresh.rejected, (state, action) => {
        state.list = state.list.map(m => {
          if (action.meta.arg.machineId === m.vm) {
            return {
              ...m,
              power_state: 'SUSPEND',
              isLoading: false,
              message: action.payload.message
            };
          }

          return m;
        })

        state.status = 'failed';
        state.error = action.payload.message;
      })
      .addCase(ticket.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(ticket.fulfilled, (state, action) => {
        state.current = action.payload; // { vm, wmksUrl }
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(ticket.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.message;
      })
      .addCase(revert.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(revert.fulfilled, (state, action) => {
        state.list = action.payload.vms;
        state.expectedVmCount = action.payload.expectedVmCount;
        state.isReverting = true;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(revert.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.message;
      })
  }
})

export const selectList = state => state.machine.list;
export const selectStatus = state => state.machine.status;
export const selectCurrentMachine = state => state.machine.current;
export const selectComplete = state => state.machine.complete;
export const selectExpectedVmCount = state => state.machine.expectedVmCount;
export const selectIsReverting = state => state.machine.isReverting;

export const { buildList, clearList, completeRevert } = machineSlice.actions
export default machineSlice.reducer
