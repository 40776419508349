/**
 * Import functions from Redux library.
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Import functions from API.
 */
import courseAPI from './courseAPI';

/**
 * Thunk to make the API call asynchronously, then load all of the courses
 * retrieved into the course slice. Use the thunkAPI to reject if the API
 * call throws an Error.
 */
export const fetchAllCourses = createAsyncThunk('course/fetchAll',
  async ({ token }, thunkAPI) => {
    try {
      const data = await courseAPI.getAllCourses(token);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Another thunk for creating a new course.
 */
export const createCourse = createAsyncThunk('course/create',
  async ({ courseData, token }, thunkAPI) => {
    try {
      const data = await courseAPI.postCourse(courseData, token);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Thunk of editing a course.
 */
export const editCourse = createAsyncThunk('course/edit',
  async ({ courseId, courseData, token }, thunkAPI) => {
    try {
      const data = await courseAPI.putCourse(courseId, courseData, token);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Thunk for deleting a course.
 */
export const deleteCourse = createAsyncThunk('course/delete',
  async ({ courseId, token }, thunkAPI) => {
    try {
      const data = await courseAPI.deleteCourse(courseId, token)
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  }
);

/**
 * Create the store slice with the usual status and an empty list of courses as the
 * initial state.
 */
export const courseSlice = createSlice({
  name: 'course',
  initialState: {
    status: 'idle',
    list: [],
    current: {},
    error: null
  },
  reducers: {
    /**
     * Completely empty the course list.
     */
    clearList: (state) => {
      return {
        ...state,
        list: []
      };
    },
    loadCurrent: (state, action) => {
      return {
        ...state,
        current: action.payload.course
      };
    },
    clearCurrent: (state) => {
      return {
        ...state,
        current: {}
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCourses.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(fetchAllCourses.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(fetchAllCourses.rejected, (state, action) => {
      state.list = [];
      state.status = 'failed';
      state.error = action.payload.message;
    });
    builder.addCase(createCourse.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(createCourse.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(createCourse.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload.message;
    });
    builder.addCase(editCourse.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(editCourse.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(editCourse.rejected, (state, action) => {
      state.status = 'failed';
      state.status = action.payload.message;
    });
    builder.addCase(deleteCourse.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(deleteCourse.fulfilled, (state, action) => {
      state.list = state.list.filter(c => c.id !== action.payload.courseId);
      state.status = 'succeeded';
      state.error = null;
    });
    builder.addCase(deleteCourse.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload.message;
    });
  }
});

/**
 * Export selectors for easily accessing certain pieces of this slice.
 */
export const selectCourseError = state => state.course.error;
export const selectCourseList = state => state.course.list;
export const selectCurrentCourse = state => state.course.current;

/**
 * Export available actions to be used by components.
 * Note that the thunks are exported at their definition.
 */
export const { clearList, loadCurrent, clearCurrent } = courseSlice.actions;

/**
 * Export the entire reducer itself. Use in the main store.js file.
 */
export default courseSlice.reducer;
