import Nav from './nav/Nav';
import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config';
import Routes from './Routes';
import Footer from './common/Footer';

const oktaAuth = new OktaAuth(config.oidc);

export default function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Flex
        backgroundColor="black.100"
        backgroundImage="/circles.svg"
        backgroundPosition="bottom -8rem left -16rem"
        backgroundRepeat="no-repeat"
        backgroundSize="100%"
        flexDirection="column"
        minHeight="100vh"
      >
        <Nav />
        <Box flex="auto">
          <Routes />
        </Box>
        <Footer />
      </Flex>
    </Security>
  );
}
